﻿/**
 *  @ngdoc controller
 *  @name Drilldot Directive
 *  @description Drill-Dot Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('emailpopupModel', function ($timeout) {
        return {
            controller: 'emailPopUpController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '=',
                patientname: '=',
                successMsg: '=',
                onSendClick: '&'
            },
            templateUrl: 'app/directive/emailPopup/emailpopupdir.html'
        }
    }).controller('emailPopUpController', function ($scope, eyeResource) {

    });

}(window.angular));